<template>
  <div id="particulars-box" class="particulars">
    <div class="particulars-time" @click="selectTime = !selectTime">
      <p>
        {{ time
        }}<i
          class="iconfont f14"
          :class="{ 'icon-xiala': !selectTime, 'icon-shangla': selectTime }"
        ></i>
      </p>
    </div>
    <div class="salary-collect">
      <h5>{{ time }}实发</h5>
      <p class="amount" v-if="salaryInfo">
        {{ salaryInfo.realPaySalaryStr }}<span class="unit">元</span>
      </p>
      <h3 v-if="!salaryInfo" class="notDataText">暂无数据</h3>
      <span>感谢为公司的付出，辛苦啦...</span>
    </div>

    <div class="detail-bottom" v-if="detail">
      <ul>
        <li>温馨提示:</li>
        <li @click="close">×</li>
      </ul>
      <p>工资属于敏感信息，请注意保密。</p>
      <p>如对工资有异议，请联系公司人事，重新核对并下发工资条。</p>
    </div>

    <div class="detail-Pages">
      <div v-if="salaryInfo">
        <ul>
          <li v-if="verifyIsShow(salaryInfo.jobGrade)">
            <span>岗位职级:</span><span>{{ salaryInfo.jobGrade }}</span>
          </li>
          <li v-if="verifyIsShow(salaryInfo.baseSalaryStr)">
            <span>基本工资:</span><span>{{ salaryInfo.baseSalaryStr }}</span>
          </li>
          <li v-if="verifyIsShow(salaryInfo.postSalaryStr)">
            <span>岗位工资:</span><span>{{ salaryInfo.postSalaryStr }}</span>
          </li>
          <li v-if="verifyIsShow(salaryInfo.jobSalaryStr)">
            <span>绩效工资:</span><span>{{ salaryInfo.jobSalaryStr }}</span>
          </li>
          <li v-if="verifyIsShow(salaryInfo.salarySumStr)">
            <span>小计:</span><span>{{ salaryInfo.salarySumStr }}</span>
          </li>
          <li v-if="verifyIsShow(salaryInfo.taskSalaryStr)">
            <span>任务工资:</span><span>{{ salaryInfo.taskSalaryStr }}</span>
          </li>

          <li v-if="verifyIsShow(salaryInfo.monthEfficiencySalaryStr)">
            <span>月度绩效奖金:</span
            ><span>{{ salaryInfo.monthEfficiencySalaryStr }}</span>
          </li>
          <li v-if="verifyIsShow(salaryInfo.quarterEfficiencySalaryStr)">
            <span>季度绩效奖金:</span
            ><span>{{ salaryInfo.quarterEfficiencySalaryStr }}</span>
          </li>
          <li v-if="verifyIsShow(salaryInfo.otSalaryStr)">
            <span>加班工资:</span><span>{{ salaryInfo.otSalaryStr }}</span>
          </li>

          <li v-if="verifyIsShow(salaryInfo.salesBonusStr)">
            <span>销售提成:</span><span>{{ salaryInfo.salesBonusStr }}</span>
          </li>
          <li v-if="verifyIsShow(salaryInfo.nightShiftSubsidyStr)">
            <span>夜班补贴:</span
            ><span>{{ salaryInfo.nightShiftSubsidyStr }}</span>
          </li>
          <li v-if="verifyIsShow(salaryInfo.workShiftSubsidyStr)">
            <span>轮班补贴:</span
            ><span>{{ salaryInfo.workShiftSubsidyStr }}</span>
          </li>
          <li
            v-if="
              verifyIsShow(salaryInfo.transportationCommunicationSubsidyStr)
            "
          >
            <span>通讯/交通补贴:</span
            ><span>{{ salaryInfo.transportationCommunicationSubsidyStr }}</span>
          </li>
          <li v-if="verifyIsShow(salaryInfo.specialSubsidyStr)">
            <span>特殊补贴:</span
            ><span>{{ salaryInfo.specialSubsidyStr }}</span>
          </li>
          <li v-if="verifyIsShow(salaryInfo.otherSalaryStr)">
            <span>其他应发:</span><span>{{ salaryInfo.otherSalaryStr }}</span>
          </li>
          <li v-if="verifyIsShow(salaryInfo.lateDeductionStr)">
            <span>迟到扣款:</span><span>{{ salaryInfo.lateDeductionStr }}</span>
          </li>
          <li v-if="verifyIsShow(salaryInfo.leaveEarlyDeductionStr)">
            <span>早退扣款:</span
            ><span>{{ salaryInfo.leaveEarlyDeductionStr }}</span>
          </li>
          <li v-if="verifyIsShow(salaryInfo.lackOfCardDeductionStr)">
            <span>补卡扣款:</span
            ><span>{{ salaryInfo.lackOfCardDeductionStr }}</span>
          </li>
          <li v-if="verifyIsShow(salaryInfo.absenteeismDeductionStr)">
            <span>旷工扣款:</span
            ><span>{{ salaryInfo.absenteeismDeductionStr }}</span>
          </li>
          <li v-if="verifyIsShow(salaryInfo.leaveDeductionStr)">
            <span>事假扣款:</span
            ><span>{{ salaryInfo.leaveDeductionStr }}</span>
          </li>

          <li v-if="verifyIsShow(salaryInfo.sickLeaveDeductionStr)">
            <span>病假扣款:</span>
            <span>{{ salaryInfo.sickLeaveDeductionStr }}</span>
          </li>
          <li v-if="verifyIsShow(salaryInfo.otherDeductionStr)">
            <span>其他扣款:</span
            ><span>{{ salaryInfo.otherDeductionStr }}</span>
          </li>

          <li v-if="verifyIsShow(salaryInfo.payableSalaryStr)">
            <span>应发工资:</span><span>{{ salaryInfo.payableSalaryStr }}</span>
          </li>
          <li v-if="verifyIsShow(salaryInfo.socialInsuranceEmployeePaymentStr)">
            <span>个人社保代扣:</span
            ><span>{{ salaryInfo.socialInsuranceEmployeePaymentStr }}</span>
          </li>
          <li v-if="verifyIsShow(salaryInfo.housingFundEmployeePaymentStr)">
            <span>个人公积金代扣:</span
            ><span>{{ salaryInfo.housingFundEmployeePaymentStr }}</span>
          </li>
          <li v-if="verifyIsShow(salaryInfo.personalIncomeTaxStr)">
            <span>个人所得税:</span
            ><span>{{ salaryInfo.personalIncomeTaxStr }}</span>
          </li>

          <!--          <li v-if="verifyIsShow(salaryInfo.afterTaxDeductionStr)">-->
          <!--            <span>税后补扣:</span><span>{{ salaryInfo.afterTaxDeductionStr }}</span>-->
          <!--          </li>-->
          <li v-if="verifyIsShow(salaryInfo.afterTaxReissueStr)">
            <span>税后补发:</span
            ><span>{{ salaryInfo.afterTaxReissueStr }}</span>
          </li>
          <li v-if="verifyIsShow(salaryInfo.afterTaxWithholdingStr)">
            <span>税后代缴/代扣:</span
            ><span>{{ salaryInfo.afterTaxWithholdingStr }}</span>
          </li>
          <li v-if="verifyIsShow(salaryInfo.paidWagesStr)">
            <span>实发工资:</span><span>{{ salaryInfo.realPaySalaryStr }}</span>
          </li>
          <li v-if="verifyIsShow(salaryInfo.remark)">
            <span>备注:</span
            ><span class="remark">{{ salaryInfo.remark }}</span>
          </li>
        </ul>
      </div>

      <h3 v-if="!salaryInfo" class="notDataText">暂无数据</h3>
    </div>
    <selectTime @confirmTime="confirmTime" :hide.sync="selectTime" />
    <loading-temp :loading="loading"> </loading-temp>
  </div>
</template>

<script>
import selectTime from '../../components/selectTime'
import { fectLooksalaryInfo } from '../../server/user'
import loadingTemp from '../../components/loading'
import { mapState } from 'vuex'
import { watermark } from '../../unit/units'
export default {
  name: 'particulars',
  data() {
    return {
      salaryInfo: null,
      loading: false,
      time: '',
      selectTime: false,
      detail: true,
    }
  },
  components: {
    loadingTemp,
    selectTime,
  },
  created() {
    this.loading = true
    let year = this.$route.params.year
    let moth = this.$route.params.month
    this.initData(year, moth)
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        watermark.setWatermark({
          id: 'particulars-box',
          userName: this.userInfo.nick_name,
        })
      })
    }, 10)
  },
  computed: {
    ...mapState(['userInfo']),
  },
  methods: {
    /**
     * 初始数据
     */
    initData(year, month) {
      this.loading = true
      this.time = year + '年' + month + '月'
      let sendData = {
        month: month,
        year: year,
      }
      fectLooksalaryInfo(sendData)
        .then((res) => {
          this.salaryInfo = res.data
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    confirmTime(val) {
      let year = val.getFullYear()
      let month = val.getMonth() + 1
      this.initData(year, month)
    },

    /**
     *
     */
    verifyIsShow(val) {
      return Number(val) !== 0
    },
    close() {
      this.detail = false
    },
  },
}
</script>

<style scoped lang="scss">
.particulars {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background: linear-gradient(#487ee2, rgb(243, 243, 248));
  padding-top: 20px;
  .particulars-time {
    width: 150px;
    height: 30px;
    background-color: #a2beef;
    font-size: 14px;
    text-align: center;
    line-height: 30px;
    margin: 0 auto;
    border-radius: 10px;
    margin-bottom: 10px;
    color: #fff;
  }

  .salary-collect {
    width: 340px;
    height: 110px;
    background-color: #fff;
    margin: 0 auto;
    border-radius: 14px;
    padding-top: 15px;
    text-align: center;
    font-size: 12px;
    margin-bottom: 10px;

    .amount {
      font-size: 36px;
      color: rgba(0, 0, 0, 0.8);
      font-weight: 700;
      margin-top: 10px;
      margin-bottom: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      .unit {
        font-size: 34px;
        margin-left: 2px;
        color: inherit;
      }
    }
  }

  .salary-collect h5 {
    color: #787878;
  }

  .salary-collect span {
    font-size: 14px;
    color: #487ee2;
  }

  .detail-Pages {
    width: 340px;
    height: 420px;
    background-color: #ffffff;
    margin: 0 auto;
    // margin-top: 20px;
    // padding-top: 20px;
    overflow: scroll;
    border-radius: 6px;

    ul {
      li {
        span {
          &:first-child {
            text-align: right;
            width: 40%;
          }
          &:last-child {
            text-align: center;
            width: 55%;
          }
        }
      }
    }
    .remark {
      overflow: visible;
      white-space: normal;
      line-height: 22px;
      padding-top: 10px;
    }

    // p {
    //   width: 300px;
    //   height: 40px;
    //   line-height: 40px;
    //   display: flex;
    //   justify-content: space-between;
    //   margin: 0 auto;
    //   padding-left: 25px;
    //   padding-right: 15px;
    //   font-size:22px;
    // }
  }

  .detail-Pages p:first-child {
    border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
  }

  .detail-Pages p:last-child {
    border-top: 1px dashed rgba(0, 0, 0, 0.3);
  }

  .detail-Pages li:last-child {
    border: none;
  }

  .detail-Pages ul li {
    width: 280px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    line-height: 40px;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
    // padding-left: 20px;
    // padding-right: 20px;
    margin: 0 auto;
    font-size: 14px;
  }

  .detail-Pages ul li span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  //.detail-Pages ul li span:last-child{
  //  font-weight:700;
  //}

  .detail-bottom {
    font-size: 12px;
    text-align: center;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 5px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    background-color: #fffbe6;
    p {
      font-weight: 600;
    }
    ul {
      display: flex;
      justify-content: space-between;
      li {
        padding: 5px;
      }
      li:first-child {
        font-weight: 900;
      }
      li:last-child {
        font-size: 28px;
      }
    }
  }

  .notDataText {
    text-align: center;
    margin: 20px 0;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.5);
  }
}
</style>
